<template>
  <v-row>
    <v-col cols="12" sm="8" md="5">
      <v-row>
        <v-col>
          <span>{{ $_strings.invoice.DATE }}</span>
          <v-menu
            ref="menuFromDate"
            v-model="menuFromDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                outlined
                class="caption"
                placeholder="From Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                :value="dayjs(filters.dateFrom).format('DD-MM-YYYY')"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filters.dateFrom"
              no-title
              :max="filters.dateTo"
              @change="menuFromDate = false"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="auto pa-0 ma-0 d-flex align-center mt-6">-</v-col>
        <v-col>
          <v-menu
            ref="menuToDate"
            v-model="menuToDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                class="caption mt-6"
                outlined
                :value="dayjs(filters.dateTo).format('DD-MM-YYYY')"
                placeholder="To Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :min="filters.dateFrom"
              v-model="filters.dateTo"
              no-title
              @change="menuToDate = false"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="showFilterBy" cols="auto" class="d-flex align-end">
      <v-menu
        bottom
        :close-on-content-click="closeOnContentClick"
        width="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            hide-details
            height="10"
            class="caption"
            prepend-inner-icon="mdi-filter"
            :placeholder="$_strings.invoice.FILTER"
            outlined
            dense
            readonly
            v-bind="attrs"
            @click="closeOnContentClick = false"
            v-on="on"
          ></v-text-field>
        </template>
        <v-list
          width="300"
          min-height="150"
          max-height="400"
          class="overflow-auto"
        >
          <section class="mb-2" v-for="(filterItem, index) in filterItems" :key="index">
            <v-list-item-title class="caption ml-4 font-weight-bold">{{filterItem.title}}</v-list-item-title>
            <v-list-item>
              <v-list-item-content class="pt-0 mt-0">
                <section
                  v-for="chip in filters[filterItem.vModel]"
                  :key="chip"
                  class="mb-2"
                >
                  <v-chip
                    close
                    small
                    @click:close="filters[filterItem.vModel].splice(filters[filterItem.vModel].indexOf(chip), 1)"
                  >
                    <strong>{{ filterItem.items.find((fi) => fi.value === chip).text }}</strong>
                  </v-chip>
                </section>
                <v-select
                  hide-details
                  flat
                  :items="filterItem.items.filter((item) => !(filters[filterItem.vModel] && filters[filterItem.vModel].includes(item.value)))"
                  :placeholder="filterItem.placeholder"
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="caption"
                  dense
                  v-model="filters[filterItem.vModel]"
                  multiple
                  chips
                >
                  <template v-slot:item="{ item }">
                    <span class="caption">{{item.text}}</span>
                  </template>
                </v-select>
              </v-list-item-content>
            </v-list-item>
          </section>
          <section class="pl-2 pr-2">
            <v-btn
              depressed
              class="mt-2 white--text"
              color="red"
              block
              @click="closeOnContentClick = true"
            >
              {{$_strings.common.CLOSE}}
            </v-btn>
          </section>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    showFilterBy: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    filters: {
      handler() {
        this.$emit('fetchData');
      },
      deep: true,
    },
  },
  data() {
    return {
      menuFromDate: false,
      menuToDate: false,
      filterItems: [
        {
          title: this.$_strings.invoice.FILTER_STATUS,
          placeholder: this.$_strings.invoice.CHOOSE_STATUS,
          vModel: 'statusInvoice',
          items: this.$_strings.invoice.FILTER_STATUS_LIST,
        },
        {
          title: this.$_strings.invoice.FILTER_TYPE,
          placeholder: this.$_strings.invoice.CHOOSE_TYPE,
          vModel: 'typeInvoice',
          items: this.$_strings.invoice.FILTER_TYPE_LIST,
        },
      ],
      closeOnContentClick: false,
    };
  },
  methods: {
    dayjs,
  },
};
</script>

<style lang="scss" scoped>
  p.p_ellipsis {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
