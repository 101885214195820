<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="1200"
  >
    <v-card>
      <v-card-title class="caption">
        <v-row>
          <v-col cols="10" class="d-flex justify-space-between align-center pb-0">
            <h3>{{$_strings.p2pLending.SELECT_INVOICE}}</h3>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="isLoading" class="mt-4">
        <v-skeleton-loader
          class="mx-auto"
          type="heading"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto mt-4"
          type="image"
        ></v-skeleton-loader>
      </v-card-text>
      <v-card-text v-else class="mt-4">
        <FilterInvoice
          :filters="filters"
          :showFilterBy="false"
          @fetchData="fetchData('isLoadingTable')"
          class="mb-4"
        />
        <v-data-table
          :headers="headers"
          :loading="isLoadingTable"
          loading-text="Mohon tunggu"
          calculate-widths
          :items="items"
          :server-items-length="listTotalEntry"
          :options.sync="pagination"
          :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
        >
          <template v-slot:[`item.radioButton`]={item}>
            <v-radio-group
              v-model="radioInvoice"
              column
            >
              <v-radio
                color="primary"
                :value="item.id"
                :disabled="invoiceNoSelected.includes(item.invoiceNo)"
              ></v-radio>
            </v-radio-group>
          </template>
          <template v-slot:[`item.periodStart`]={item}>
            <p class="mb-0 font-weight-bold">
              {{differenceMonth(item.periodStart, item.periodEnd)}} Bulan
            </p>
            <p>
              {{`${dateFormat(item.periodStart)} - ${dateFormat(item.periodEnd)}`}}
            </p>
          </template>
          <template v-slot:[`item.totalAmount`]={item}>
            <span>{{item.totalAmount && `Rp. ${formatCurrency(item.totalAmount)}`}}</span>
          </template>
          <template v-slot:[`item.invoiceDate`]={item}>
            <span>
              {{`${dateFormat(item.invoiceDate)}`}}
            </span>
          </template>
          <template v-slot:[`item.status`]={item}>
            <span>{{$_strings.invoice.FILTER_STATUS_LIST.find((filter) => filter.value === item.status).text}}</span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions v>
        <v-row
          justify="end"
          class="ma-0"
        >
          <v-col
            cols="12"
            md="2"
            sm="3"
          >
            <v-btn
              color="red"
              class="white--text"
              small
              block
              @click="dialog = false"
            >
              {{$_strings.common.CANCEL}}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="2"
            sm="3"
          >
            <v-btn
              color="primary"
              class="white--text"
              small
              block
              :disabled="isLoading || isLoadingTable || !radioInvoice"
              @click="submit"
            >
              {{$_strings.common.CHOOSE}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { dateFormat } from '@/helper/commonHelpers';
import FilterInvoice from '../FilterInvoice.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  props: {
    invoiceNoSelected: Array,
  },
  components: {
    FilterInvoice,
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.radioInvoice = null;
        source.cancel('CANCELED_BY_THE_USER');
        return;
      }
      source = CancelToken.source();
      if (!this.items.length) {
        this.fetchData('isLoading');
      }
    },
    pagination: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
        this.fetchData('isLoadingTable');
      },
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingTable: false,
      indexInvoiceSelected: null,
      dialog: false,
      headers: [
        {
          text: '',
          value: 'radioButton',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.NO_INVOICE,
          value: 'invoiceNo',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.PERIOD_ORDER,
          value: 'periodStart',
          width: '250px',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.TOTAL_BILL,
          value: 'totalAmount',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.INVOICE_DATE,
          value: 'invoiceDate',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.TOP,
          value: 'paymentTerm',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.STATUS,
          value: 'status',
          class: 'white--text primary',
          sortable: false,
        },
      ],
      items: [],
      radioInvoice: null,
      filters: {
        invoiceNo: '',
        dateFrom: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
        dateTo: dayjs().format('YYYY-MM-DD'),
        statusInvoice: [],
        typeInvoice: [],
        billedToCompanyId: '',
      },
      listTotalEntry: 1,
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },

    };
  },
  methods: {
    dateFormat,
    dayjs,
    formatCurrency(amount) {
      return (amount && Intl.NumberFormat('id-ID').format(amount)) || 0;
    },
    differenceMonth(startDate, endDate) {
      const date1 = dayjs(endDate);
      return date1.diff(startDate, 'month');
    },
    async fetchData(isLoading) {
      try {
        this[isLoading] = true;
        const {
          page, itemsPerPage, sortBy, sortDesc,
        } = this.pagination;
        const filters = {
          page: page - 1,
          size: itemsPerPage,
        };
        Object.keys(this.filters).forEach((filter) => {
          if (typeof this.filters[filter] === 'object' && this.filters[filter].length) {
            filters[filter] = `qin(${this.filters[filter].toString()})`;
            return;
          }
          if (this.filters[filter] && typeof this.filters[filter] !== 'object') {
            filters[filter] = this.filters[filter];
          }
        });
        if (sortBy.length) {
          const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
          filters.sort = `${sortBy[0]},${isDesc}`;
        }
        this.items = [];
        this.radioInvoice = null;
        const result = await this.$_services.p2pLending.selectInvoiceP2pLending(filters, source);
        this.items = result.data.contents.map((data) => ({
          ...data,
          totalAmount: data.totalAmount,
        }));
        this.listTotalEntry = result.data.totalData;
      } finally {
        this[isLoading] = false;
      }
    },
    async submit() {
      const itemSelected = this.items.find((item) => item.id === this.radioInvoice);
      this.$emit('selectInvoice', this.indexInvoiceSelected, itemSelected);
      this.dialog = false;
    },
  },
};
</script>
