<template>
  <v-container fluid>
    <v-sheet rounded class="pt-4 pl-1">
      <section class="section-1 pl-4">
        <h4>{{$_strings.p2pLending.CREATE_P2P_LENDING}}</h4>
        <v-row class="mt-4">
          <v-col class="pb-0 pb-sm-4" cols="12" sm="4" md="3">
            <span>{{$_strings.p2pLending.NO_P2P_LENDING}}</span>
          </v-col>
          <v-col class="pt-0 pt-sm-4" cols="12" sm="8" md="9">
            <span>{{$route.query.documentNo || '-'}}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pb-sm-4" cols="12" sm="4" md="3">
            <span>{{$_strings.p2pLending.CREATED_AT}}</span>
          </v-col>
          <v-col class="pt-0 pt-sm-4" cols="12" sm="8" md="9">
            <span>{{($route.query.createdAt && dateFormat($route.query.createdAt)) || '-'}}</span>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col>
            <h4>{{$_strings.p2pLending.SUMMARY}}</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pb-sm-4" cols="12" sm="4" md="3">
            <span>{{$_strings.p2pLending.TOTAL_INVOICE}}</span>
          </v-col>
          <v-col v-if="isLoading" class="pt-0 pt-sm-4" cols="6">
            <v-skeleton-loader
              type="heading"
            ></v-skeleton-loader>
          </v-col>
          <v-col v-else class="pt-0 pt-sm-4" cols="12" sm="8" md="9">
            <span>Rp. {{formatCurrency(form.totalInvoice)}}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pb-sm-4" cols="12" sm="4" md="3">
            <span>{{$_strings.p2pLending.TOTAL_P2P_LENDING}}</span>
          </v-col>
          <v-col v-if="isLoading" class="pt-0 pt-sm-4" cols="6">
            <v-skeleton-loader
              type="heading"
            ></v-skeleton-loader>
          </v-col>
          <v-col v-else class="pt-0 pt-sm-4" cols="12" sm="8" md="9">
            <span class="font-weight-bold">Rp. {{form.totalP2pLending ? formatCurrency(form.totalP2pLending) : 0}}</span>
          </v-col>
        </v-row>
      </section>
      <v-row v-if="isLoading">
        <v-col>
          <v-skeleton-loader
            class="px-2"
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else-if="!isLoading && isError" class="mt-5">
        <v-col cols="12">
          <h4 class="text-center red--text">{{$_strings.p2pLending.ERROR_MESSAGE}}</h4>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-icon @click="fetchDetail">
            mdi-reload
          </v-icon>
        </v-col>
      </v-row>
      <v-row v-if="isLoadingListDetail">
        <v-col>
          <v-skeleton-loader
            class="px-2"
            type="image"
            height="50"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else-if="!isLoadingListDetail && isErrorGetListDetail" class="mt-5">
        <v-col cols="12">
          <h4 class="text-center red--text">{{$_strings.p2pLending.ERROR_MESSAGE}}</h4>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-icon @click="fetchListDetail">
            mdi-reload
          </v-icon>
        </v-col>
      </v-row>
      <section v-else class="section-2">
        <v-row justify="center" class="pa-2">
          <v-expansion-panels
            multiple
            accordion
            flat
          >
            <v-expansion-panel
              v-for="(item,index) in form.details"
              :key="index"
              @click="item.id && !formUpload[index] ? fetchDocumentDetail(index, item.id, item) : true"
            >
              <v-expansion-panel-header class="grey lighten-5">
                <v-row
                  justify="space-between"
                  align="center"
                >
                  <v-col cols="12" sm="auto">
                    <h4>Invoice {{index + 1}}</h4>
                  </v-col>
                  <v-col
                    v-if="actionPath === 'detail'
                      && p2pDetail[index].lendingStatus
                      && p2pDetail[index].lendingStatus.toLowerCase() !== 'draft'
                    "
                    cols="12"
                    sm="4"
                    class="d-flex justify-sm-end"
                  >
                    <v-chip
                      :color="getColorByStatus(p2pDetail[index].lendingStatus).background"
                      :class="getColorByStatus(p2pDetail[index].lendingStatus).textColor"
                      label
                      class="text-capitalize"
                    >
                      {{p2pLendingStatus(p2pDetail[index].lendingStatus)}}
                    </v-chip>
                    <v-chip
                      v-if="p2pDetail[index].paymentStatus"
                      :color="getColorByStatus(p2pDetail[index].paymentStatus).background"
                      :class="getColorByStatus(p2pDetail[index].paymentStatus).textColor"
                      class="ml-2 text-capitalize"
                      label
                    >
                      {{p2pDetail[index].paymentStatus}}
                    </v-chip>
                  </v-col>
                  <v-col v-if="actionPath !== 'detail'" cols="4" class="d-flex justify-end">
                    <v-btn
                      v-if="(userAccess.canDelete && item.id) || !item.id"
                      color="red"
                      class="white--text"
                      small
                      @click="($event) => handleDeleteInvoice($event, index, { detailId: item.id })"
                    >
                      {{$_strings.p2pLending.DELETE_INVOICE}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-divider></v-divider>
              <v-form ref="form" lazy-validation>
                <v-expansion-panel-content
                  style="border: 1px solid #DDDD;"
                >
                  <v-row align="center" class="pt-4">
                    <v-col cols="12" sm="4" md="3">
                      <span>{{$_strings.p2pLending.NO_INVOICE}}</span>
                    </v-col>
                    <v-col cols="8" sm="6" md="6">
                      <v-text-field
                        outlined
                        dense
                        readonly
                        hide-details
                        :disabled="actionPath === 'detail'"
                        v-model="item.invoiceNo"
                        :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.p2pLending.NO_INVOICE)]"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="actionPath !== 'detail'" cols="auto">
                      <v-btn
                        color="primary"
                        small
                        @click="toggleDialogSelectInvoice(index)"
                      >
                        {{$_strings.common.CHOOSE}}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row align="center" class="pt-4">
                    <v-col cols="12" sm="4" md="3">
                      <span>{{$_strings.p2pLending.PERIOD_ORDER}}</span>
                    </v-col>
                    <v-col cols="12" sm="8" md="9">
                      <p v-if="invoiceSelected[index].periodStart && invoiceSelected[index].periodEnd" class="pa-0 ma-0">
                        <span class="font-weight-bold">{{differenceMonth(invoiceSelected[index].periodStart, invoiceSelected[index].periodEnd)}} Bulan</span>
                        <span> {{`${dateFormat(invoiceSelected[index].periodStart)} - ${dateFormat(invoiceSelected[index].periodEnd)}`}}</span>
                      </p>
                      <p v-else>-</p>
                    </v-col>
                  </v-row>
                  <v-row align="center" class="pt-4">
                    <v-col cols="12" sm="4" md="3">
                      <span>{{$_strings.p2pLending.TOTAL_BILL}}</span>
                    </v-col>
                    <v-col cols="12" sm="8" md="9">
                      <span>Rp. {{invoiceSelected[index].totalAmount ? formatCurrency(invoiceSelected[index].totalAmount) : 0}}</span>
                    </v-col>
                  </v-row>
                  <v-row align="center" class="pt-4">
                    <v-col cols="12" sm="4" md="3">
                      <span>{{$_strings.p2pLending.INVOICE_DATE}}</span>
                    </v-col>
                    <v-col cols="12" sm="8" md="9">
                      <span>{{invoiceSelected[index].invoiceDate ? dateFormat(invoiceSelected[index].invoiceDate) : '-'}}</span>
                    </v-col>
                  </v-row>
                  <v-row align="center" class="pt-4">
                    <v-col cols="12" sm="4" md="3">
                      <span>{{$_strings.p2pLending.TOP}}</span>
                    </v-col>
                    <v-col cols="12" sm="8" md="9">
                      <span>{{invoiceSelected[index].paymentTerm ? `${invoiceSelected[index].paymentTerm} hari` : '-'}} </span>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-4 mb-4"></v-divider>
                  <v-row align="center" class="pt-4">
                    <v-col cols="12" sm="4" md="3">
                      <p>{{$_strings.p2pLending.TOP_P2P_LENDING}}</p>
                    </v-col>
                    <v-col cols="12" sm="auto">
                      <v-select
                        :loading="isLoadingTopP2pLending"
                        :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.p2pLending.TOP_P2P_LENDING)]"
                        height="10"
                        :items="itemsTopP2pLending"
                        v-model="item.p2pTopDays"
                        item-text="top"
                        item-value="top"
                        @change="setItemsCalculator(index, item.p2pTopDays)"
                        class="caption"
                        placeholder="Pilih"
                        outlined
                        dense
                        :disabled="actionPath === 'detail'"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-5">
                    <v-col cols="12">
                      <h4>{{$_strings.p2pLending.CALCULATOR}}</h4>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="tableHeaderCalculator"
                        :items="itemsCalculator[index]"
                        class="elevation-1 table"
                        item-key="index"
                        hide-default-footer
                        :server-items-length="100"
                      >
                        <template v-slot:[`item.adminRate`]={item}>
                          <span>{{replaceDotToComma(item.adminRate)}}%</span>
                        </template>
                        <template v-slot:[`item.interestRate`]={item}>
                          <span>{{replaceDotToComma(item.interestRate)}}%</span>
                        </template>
                        <template v-slot:[`item.totalAmount`]={item}>
                          <span>Rp. {{formatCurrency(item.totalAmount)}}</span>
                        </template>
                        <template v-slot:[`item.totalP2pLending`]={item}>
                          <span>Rp. {{formatCurrency(item.totalP2pLending)}}</span>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-col class="pb-0 pb-sm-4" cols="12" sm="4" md="3">
                      <p class="mb-0">{{$_strings.p2pLending.DUE_DATE}}</p>
                      <span>{{$_strings.p2pLending.INITIAL_INVOICE}}</span>
                    </v-col>
                    <v-col class="pt-0 pt-sm-4" cols="12" sm="8" md="9">
                      <span>
                        {{invoiceSelected[index].invoiceDueDate ? dateFormat(invoiceSelected[index].invoiceDueDate) : '-'}}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0 pb-sm-4" cols="12" sm="4" md="3">
                      <p class="mb-0">{{$_strings.p2pLending.DUE_DATE}}</p>
                      <span>{{$_strings.p2pLending.INVOICE_P2P_LENDING}}</span>
                    </v-col>
                    <v-col class="pt-0 pt-sm-4" cols="12" sm="8" md="9">
                      <span class="font-weight-bold">
                        {{form.details[index].p2pDueDate ? dateFormat(form.details[index].p2pDueDate) : '-'}}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.isLoadingDocuments" class="mt-5">
                    <v-col cols="12">
                      <v-skeleton-loader
                        type="heading"
                        width="800"
                      ></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="3" v-for="docImage in 3" :key="docImage">
                      <v-skeleton-loader
                        type="image"
                      ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <section v-if="item.id && (formUpload.length && formUpload[index] && formUpload[index].length && !item.isLoadingDocuments)" >
                    <v-divider class="my-4"></v-divider>
                    <v-row class="mt-5">
                      <v-col cols="12">
                        <h4>{{$_strings.p2pLending.DOCUMENT_SUPPORT}}</h4>
                      </v-col>
                    </v-row>
                    <v-row class="mt-5">
                      <v-col cols="12" sm="3" v-for="(itemFormUploadFile, indexFormUpload) in formUpload[index]" :key="indexFormUpload">
                        <div class="img-box">
                          <div v-if="itemFormUploadFile.encodedImages[0].encodedImage && isDraft" class="box-mdi-close">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  @click="handleDeleteDocument(index, itemFormUploadFile, { detailId: item.id }, item)"
                                  v-bind="attrs"
                                  v-on="on"
                                  color="red"
                                >
                                  mdi-close
                                </v-icon>
                              </template>
                              <span>Hapus</span>
                            </v-tooltip>
                          </div>
                          <div
                            v-if="itemFormUploadFile.isLoading"
                            style="height: 120px;"
                            class="d-flex justify-center align-center"
                          >
                            <v-progress-circular
                              :size="100"
                              :width="15"
                              :value="itemFormUploadFile.progressUpload"
                              color="primary"
                            >
                              {{ itemFormUploadFile.progressUpload }}
                            </v-progress-circular>
                          </div>
                          <div
                            class="d-flex justify-center"
                            v-if="itemFormUploadFile.encodedImages[0].encodedImage"
                          >
                            <v-icon
                              size="120"
                              @click="openDocument(itemFormUploadFile.encodedImages[0].encodedImage)"
                            >
                              mdi-file
                            </v-icon>
                          </div>
                        </div>
                        <p class="text-center my-4 font-weight-bold">{{itemFormUploadFile.label}}</p>
                        <v-file-input
                          class="file_input d-none"
                          height="20"
                          :clearable="false"
                          v-model="fileUploadTemporary"
                          @change="($event) => setFileInput($event, index, indexFormUpload, itemFormUploadFile.documentType, item)"
                          :ref="`${itemFormUploadFile.documentType}${indexFormUpload}${index}`"
                          type="file"
                          accept=".pdf,.jpeg,.jpg,.png"
                          outlined
                          dense
                          placeholder="Pilih File"
                          prepend-icon=""
                        >
                          <template v-slot:selection="{ text }">
                            <v-chip
                              style="w-50"
                              small
                              label
                              color="primary"
                            >
                              {{ text.length > 20 ? text.substr(0, 20) : text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                        <v-btn
                          v-if="isDraft && !itemFormUploadFile.encodedImages[0].encodedImage"
                          :loading="itemFormUploadFile.isLoading"
                          :disabled="itemFormUploadFile.isLoading"
                          color="blue-grey"
                          small
                          block
                          class="white--text"
                          @click="$refs[`${itemFormUploadFile.documentType}${indexFormUpload}${index}`][0].$refs.input.click()"
                        >
                          Pilih File
                          <v-icon
                            right
                            dark
                          >
                            mdi-cloud-upload
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </section>
                  <v-row v-if="!item.isLoadingDocuments && item.isErrorGetDocuments" class="mt-5">
                    <v-col cols="12">
                      <h4 class="text-center red--text">{{$_strings.p2pLending.ERROR_MESSAGE_DOCUMENTS}}</h4>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <v-icon @click="fetchDocumentDetail(index, item.id, item)">
                        mdi-reload
                      </v-icon>
                    </v-col>
                  </v-row>
                  <status-detail
                    v-if="
                      actionPath === 'detail'
                        && p2pDetail[index].lendingStatus !== 'DRAFT'
                        && p2pDetail[index].lendingStatus !== 'NEED_APPROVAL'
                    "
                    class="mt-8"
                    :datas="p2pDetail[index]"
                  />
                </v-expansion-panel-content>
              </v-form>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </section>
      <section class="section-3">
        <v-row v-if="isLoadingListDetail">
          <v-col>
            <v-skeleton-loader
              class="px-2"
              type="image"
              height="50"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row v-else justify="space-between" class="pa-4">
          <v-col cols="12" sm="3" md="2">
            <v-btn
              v-if="actionPath === 'create' || isDraft"
              color="cyan lighten-5"
              class="cyan--text"
              small
              block
              @click="addInvoice"
            >
              <v-icon small>
                mdi-plus
              </v-icon>
              {{$_strings.p2pLending.INVOICE}}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="9" md="10">
            <v-row justify="end">
              <v-col cols="12" sm="3">
                <v-btn
                  color="red"
                  class="white--text"
                  small
                  block
                  @click="$router.go(-1)"
                >
                  {{actionPath === 'create' ? $_strings.common.CANCEL :  $_strings.common.BACK}}
                </v-btn>
              </v-col>
              <v-col v-if="actionPath === 'create' && !isDraft" cols="12" sm="3">
                <v-btn
                  color="primary"
                  class="white--text"
                  small
                  block
                  @click="createP2pLending"
                  :disabled="!form.details.length"
                >
                  {{$_strings.p2pLending.NEXT}}
                </v-btn>
              </v-col>
              <v-col v-if="actionPath === 'edit' && isDraft && form.details.length" cols="12" sm="3">
                <v-btn
                  color="primary"
                  class="white--text"
                  small
                  block
                  :disabled="!form.details.length"
                  @click="save"
                >
                  {{$_strings.p2pLending.SAVE}}
                </v-btn>
              </v-col>
              <v-col v-if="actionPath === 'edit' && isDraft && form.details.length && showButtonSubmit"  cols="12" sm="3">
                <v-btn
                  color="primary"
                  class="white--text"
                  small
                  block
                  :disabled="!form.details.length"
                  @click="submit"
                >
                  {{$_strings.p2pLending.SUBMIT}}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </section>
    </v-sheet>
    <DialogSelectInvoice
      ref="dialogSelectInvoice"
      :invoiceNoSelected="invoiceSelected.map((x) => x.invoiceNo)"
      @selectInvoice="selectInvoice"
    />
    <DialogConfirmation
      ref="dialogConfirmation"
      @deleteInvoice="deleteInvoice"
      @deleteDocument="deleteDocument"
    />
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat, p2pLendingStatus } from '@/helper/commonHelpers';
import DialogSelectInvoice from './Dialog/SelectInvoice.vue';
import DialogConfirmation from './Dialog/Confirmation.vue';
import StatusDetail from './StatusDetail.vue';

export default {
  name: 'create-p2p-lending',
  components: {
    DialogSelectInvoice,
    DialogConfirmation,
    StatusDetail,
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    actionPath() {
      this.fetchData();
    },
  },
  computed: {
    actionPath() {
      // create, detail, edit
      const [, , , path] = this.$route.path.split('/');
      return path;
    },
    showButtonSubmit() {
      let result = true;
      if (this.form.details.length) {
        this.form.details.forEach((form) => {
          if (!form.id) result = false;
        });
      } else {
        result = false;
      }
      return result;
    },
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      isLoadingListDetail: false,
      isErrorGetListDetail: false,
      isLoadingDocuments: false,
      isLoadingTopP2pLending: false,
      isDraft: false,
      form: {
        totalInvoice: null,
        totalP2pLending: null,
        details: [],
      },
      p2pDetail: {},
      invoiceSelected: [],
      itemsTopP2pLending: [],
      tableHeaderCalculator: [
        {
          text: this.$_strings.p2pLending.TOTAL_INVOICE,
          value: 'totalAmount',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.ADMIN_FEES,
          value: 'adminRate',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.INTEREST_COST,
          value: 'interestRate',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.INITIAL_TOP,
          value: 'initialTop',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.TOP_P2P_LENDING_2,
          value: 'topP2PLending',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.TOTAL_PAYMENT_TO_3PL,
          value: 'totalP2pLending',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
      ],
      fileUploadTemporary: null,
      formUpload: [],
      itemsCalculator: [],
    };
  },
  methods: {
    dateFormat,
    dayjs,
    p2pLendingStatus,
    getColorByStatus(status) {
      switch (status.toLowerCase()) {
        case 'need_approval':
        case 'menunggu persetujuan':
        case 'belum dibayar':
          return {
            background: 'yellow',
            textColor: 'black--text',
          };
        case 'approved':
        case 'disetujui':
          return {
            background: 'green',
            textColor: 'white--text',
          };
        case 'ditolak':
        case 'rejected':
          return {
            background: 'red',
            textColor: 'white--text',
          };
        default:
          return {
            background: 'primary',
            textColor: 'white--text',
          };
      }
    },
    twoDigitComma(number) {
      const splitNumber = String(number).split('.');
      let _number = number;
      if (splitNumber.length > 1) {
        if (splitNumber[1].length > 2) {
          _number = parseFloat(number).toFixed(2);
        }
      }
      return _number;
    },
    replaceDotToComma(number) {
      const _number = this.twoDigitComma(number);
      const numberToString = _number.toString();
      return numberToString && numberToString.toString().indexOf('.') > -1 ? numberToString.toString().replace('.', ',') : numberToString;
    },
    fetchData() {
      if (this.actionPath !== 'detail') this.fetchTopP2pLending();
      if (this.actionPath === 'detail' || (this.actionPath === 'edit')) {
        this.fetchDetail();
        if (this.actionPath === 'edit') {
          this.fetchListDetail();
        }
      }
    },
    formatCurrency(amount) {
      let currency = 0;
      if (amount) {
        currency = Intl.NumberFormat('id-ID').format(this.twoDigitComma(amount));
      }
      return currency;
    },
    differenceMonth(startDate, endDate) {
      const date1 = dayjs(endDate);
      return date1.diff(startDate, 'month');
    },
    setItemsCalculator(index, top = null) {
      let findItemTop = {};
      let totalP2pLending = 0;
      if (top) {
        findItemTop = this.itemsTopP2pLending.find((item) => item.top === top);
        this.form.details[index].mouP2pLendingId = findItemTop.id;
        this.form.details[index].mouId = findItemTop.mouId;
        this.form.details[index].adminRate = findItemTop.adminRate;
        this.form.details[index].interestRate = findItemTop.interestRate;
        if (this.invoiceSelected[index].invoiceDate) {
          this.form.details[index].p2pDueDate = dayjs(this.invoiceSelected[index].invoiceDate).add(findItemTop.top, 'days').format('YYYY-MM-DD');
        }
      }
      if (findItemTop.adminRate && findItemTop.interestRate && this.invoiceSelected[index].totalAmount) {
        const { totalInvoice } = this.form.details[index];
        const adminRateDecimal = findItemTop.adminRate / 100;
        const interestRateDecimal = findItemTop.interestRate / 100;
        const adminAmount = totalInvoice * adminRateDecimal;
        const interestAmount = (totalInvoice - adminAmount) * interestRateDecimal;
        totalP2pLending = (totalInvoice - adminAmount) - interestAmount;
        this.form.details[index].totalP2pLending = totalP2pLending;
      }
      this.itemsCalculator[index] = [{
        totalAmount: this.invoiceSelected[index].totalAmount,
        adminRate: findItemTop.adminRate || 0,
        interestRate: findItemTop.interestRate || 0,
        initialTop: this.invoiceSelected[index].paymentTerm,
        topP2PLending: top || 0,
        totalP2pLending,
      }];
      this.setTotalInvoice();
      this.setTotalP2pLending();
    },
    async fetchTopP2pLending() {
      try {
        this.isLoadingTopP2pLending = true;
        const result = await this.$_services.p2pLending.topP2pLending();
        this.itemsTopP2pLending = [...this.itemsTopP2pLending, ...result.data.map((res) => ({
          ...res,
          adminRate: this.twoDigitComma(res.adminRate),
          interestRate: this.twoDigitComma(res.interestRate),
        }))];
      } finally {
        this.isLoadingTopP2pLending = false;
      }
    },
    addInvoice() {
      this.form.details.push(
        {
          invoiceId: null,
          invoiceNo: null,
          totalInvoice: 0,
          mouId: null,
          mouP2pLendingId: null,
          p2pTopDays: null,
          adminRate: null,
          interestRate: null,
          totalP2pLending: 0,
          invoiceDueDate: null,
          p2pDueDate: null,
        },
      );
      this.itemsCalculator.push([{
        totalAmount: 0,
        adminRate: 0,
        interestRate: 0,
        initialTop: 0,
        topP2PLending: 0,
        totalP2pLending: 0,
      }]);
      this.invoiceSelected.push({
        id: null,
        invoiceNo: null,
        periodStart: null,
        periodEnd: null,
        totalAmount: null,
        invoiceDate: null,
        receiveDate: null,
        paymentTerm: null,
        status: null,
        invoiceDueDate: null,
      });
    },
    setTotalInvoice() {
      this.form.totalInvoice = this.form.details.reduce((total, currentValue) => +currentValue.totalInvoice + total, 0);
    },
    setTotalP2pLending() {
      const totalP2pLending = this.form.details.reduce((total, currentValue) => currentValue.totalP2pLending + total, 0);
      this.form.totalP2pLending = this.twoDigitComma(totalP2pLending);
    },
    handleDeleteInvoice(event, index, { detailId }) {
      if (detailId) {
        const msg = 'Apakah Anda Yakin Akan Menghapus Invoice ini?';
        this.$refs.dialogConfirmation.title = `<div class="font-weight-bold"><p class="text-center mb-0">${msg}</p></div>`;
        this.$refs.dialogConfirmation.emit = 'deleteInvoice';
        this.$refs.dialogConfirmation.item = {
          index,
          detailId,
        };
        this.$refs.dialogConfirmation.dialog = true;
      } else {
        this.form.details.splice(index, 1);
        this.invoiceSelected.splice(index, 1);
        this.itemsCalculator.splice(index, 1);
        this.formUpload.splice(index, 1);
        this.setTotalInvoice();
        this.setTotalP2pLending();
      }
      if (event) event.stopPropagation();
    },
    toggleDialogSelectInvoice(index) {
      this.$refs.dialogSelectInvoice.indexInvoiceSelected = index;
      this.$refs.dialogSelectInvoice.dialog = true;
    },
    selectInvoice(index, itemSelected) {
      this.invoiceSelected.splice(index, 1, itemSelected);
      // SET FORM
      this.form.details[index].invoiceId = itemSelected.id;
      this.form.details[index].invoiceDueDate = itemSelected.invoiceDueDate;
      this.form.details[index].invoiceNo = itemSelected.invoiceNo;
      this.form.details[index].totalInvoice = this.twoDigitComma(itemSelected.totalAmount);

      this.setItemsCalculator(index, this.itemsCalculator[index][0].topP2PLending);
      this.setTotalInvoice();
      this.setTotalP2pLending();
    },
    fileUploadValidation(event, fileSizeMin = 1024000) {
      if (!event) return;
      const { size, name } = event;
      const fileType = name.split('.').pop().toLowerCase();
      // 1024000 => 1MB
      const fileTypeSupport = ['jpg', 'jpeg', 'png', 'pdf', 'bmp'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > fileSizeMin) {
        this.$dialog.notify.error(`Ukuran file tidak boleh lebih dari ${fileSizeMin / 1024000}MB.`);
        return false;
      }
      return true;
    },
    setFileInput(file, index, indexFormUpload, documentType, item) {
      const valid = this.fileUploadValidation(file);
      if (!valid) {
        return false;
      }
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }

      reader.onloadend = (e) => {
        const form = {
          documentId: null,
          documentType,
          encodedImage: e.target.result,
        };
        this.uploadDocument(index, indexFormUpload, form, item);
      };
    },
    handleDeleteDocument(index, itemFormUploadFile, { detailId }, item) {
      const msg = 'Anda yakin akan menghapus dokumen ini?';
      this.$refs.dialogConfirmation.title = `<div class="font-weight-bold"><p class="text-center mb-0">${msg}</p></div>`;
      this.$refs.dialogConfirmation.emit = 'deleteDocument';
      this.$refs.dialogConfirmation.item = {
        index,
        documentId: itemFormUploadFile.documentId,
        detailId,
        item,
      };
      this.$refs.dialogConfirmation.dialog = true;
    },
    openDocument(url) {
      window.open(url);
    },
    async uploadDocument(index, indexFormUpload, form, item) {
      this.$dialog.confirm({
        text: `Anda yakin akan mengupload "${this.formUpload[index][indexFormUpload].label}"?`,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then(async (res) => {
        if (!res) {
          this.fileUploadTemporary = null;
          return;
        }
        try {
          this.formUpload[index][indexFormUpload].isLoading = true;
          this.fileUploadTemporary = null;
          const { id } = this.$route.params;
          const self = this;
          const config = {
            onUploadProgress(progressEvent) {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              self.formUpload[index][indexFormUpload].progressUpload = percentCompleted;
            },
          };
          const detailId = this.form.details[index].id;
          await this.$_services.p2pLending.uploadDocumentP2pLending(id, detailId, form, config);
          this.$dialog.notify.success('Berhasil mengupload dokumen');
          this.fetchDocumentDetail(index, detailId, item);
        } finally {
          this.formUpload[index][indexFormUpload].isLoading = false;
          this.formUpload[index][indexFormUpload].progressUpload = 0;
        }
      });
    },
    async deleteInvoice({ index, detailId }) {
      this.handleDeleteInvoice(null, index, { detailId: false });
    },
    async fetchListDetail() {
      const { id } = this.$route.params;
      const {
        dateFrom,
        dateTo,
        page,
        size,
        sort,
      } = this.$route.query;
      const filters = {
        sort,
        dateFrom,
        dateTo,
        page,
        size,
      };
      try {
        this.isLoadingListDetail = true;
        this.isErrorGetListDetail = false;
        const result = await this.$_services.p2pLending.listP2pLending(filters);
        const findById = result.data.contents.find((res) => res.id === +id);
        this.isDraft = (findById.status && findById.status.toLowerCase() === 'draft') || false;
      } catch {
        this.isErrorGetListDetail = true;
      } finally {
        this.isLoadingListDetail = false;
      }
    },
    async fetchDetail() {
      try {
        this.isLoading = true;
        this.isError = false;
        const { id } = this.$route.params;
        this.invoiceSelected = [];
        this.itemsTopP2pLending = [];
        this.form.details = [];
        const result = await this.$_services.p2pLending.detailP2pLending(id);
        this.p2pDetail = result.data;
        result.data.forEach((res, index) => {
          this.invoiceSelected.push({
            id: res.id,
            invoiceNo: res.invoiceNo,
            periodStart: res.periodStart,
            periodEnd: res.periodEnd,
            totalAmount: res.totalInvoice,
            invoiceDate: res.invoiceDate,
            receiveDate: res.receiveDate,
            paymentTerm: +res.paymentTerm,
            status: res.paymentStatus,
            invoiceDueDate: res.invoiceDueDate,
          });
          this.form.details.push({
            id: res.id,
            invoiceId: res.invoiceId,
            invoiceNo: res.invoiceNo,
            totalInvoice: res.totalInvoice,
            mouId: res.mouId,
            mouP2pLendingId: res.mouP2pLendingId,
            p2pTopDays: Number(res.p2pTopDays),
            adminRate: res.adminRate,
            interestRate: res.interestRate,
            totalP2pLending: res.totalP2pLending,
            invoiceDueDate: res.invoiceDueDate,
            p2pDueDate: res.p2pDueDate,
          });
          this.itemsTopP2pLending.push({
            adminRate: parseFloat(res.adminRate),
            interestRate: parseFloat(res.interestRate),
            top: Number(res.p2pTopDays),
            mouId: res.mouId,
          });
          // SET TOTAL
          this.setItemsCalculator(index, Number(res.p2pTopDays));
        });
      } catch (err) {
        if (err.response.status === 404) this.$router.go(-1);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async fetchDocumentDetail(index, detailId, item = {}) {
      try {
        this.$set(item, 'isLoadingDocuments', true);
        this.$delete(item, 'isErrorGetDocuments');
        const { id } = this.$route.params;
        const result = await this.$_services.p2pLending.documentP2pLending(id, detailId);
        const DOCUMENT_TYPES = ['FOTO_SURAT_JALAN', 'FOTO_SURAT_SERAH_TERIMA', 'FOTO_BAST', 'FOTO_BASP'];
        this.formUpload[index] = [];
        if (this.actionPath === 'edit') {
          const types = [];
          result.data.forEach((data) => {
            types.push(data.documentType);
          });
          DOCUMENT_TYPES.forEach((docType) => {
            if (!types.includes(docType)) {
              this.formUpload[index].push({
                documentType: docType,
                documentId: null,
                encodedImages: [
                  {
                    encodedImage: null,
                    fileType: '',
                  },
                ],
                label: docType.replaceAll('_', ' '),
                isLoading: false,
                progressUpload: 0,
              });
            }
          });
        }
        const documents = [];
        result.data.forEach((data) => {
          documents.push({
            documentType: data.documentType,
            documentId: data.documents[0].documentId,
            encodedImages: [
              {
                encodedImage: data.documents[0].url,
                fileType: '',
              },
            ],
            label: data.documentType.replaceAll('_', ' '),
            isLoading: false,
            progressUpload: 0,
          });
        });
        this.formUpload.splice(index, 1, [...this.formUpload[index], ...documents]);
      } catch {
        this.$set(item, 'isErrorGetDocuments', true);
      } finally {
        this.$delete(item, 'isLoadingDocuments');
        this.isLoadingDocuments = false;
      }
    },
    async deleteDocument({
      index, documentId, detailId, item,
    }) {
      const { id } = this.$route.params;
      try {
        this.$root.$loading.show();
        await this.$_services.p2pLending.deleteDocumentP2pLending(id, detailId, documentId);
        this.$dialog.notify.success('Dokumen Berhasil Dihapus');
        this.fetchDocumentDetail(index, detailId, item);
      } finally {
        this.$root.$loading.hide();
      }
    },
    async navigateToEditPageP2pLending() {
      try {
        this.$root.$loading.show();
        const filters = {
          page: 0,
          size: 10,
          sort: 'createdAt,desc',
          dateFrom: dayjs().format('YYYY-MM-DD'),
          dateTo: dayjs().format('YYYY-MM-DD'),
        };
        const result = await this.$_services.p2pLending.listP2pLending(filters);
        const item = result.data.contents[0];
        if (item) {
          this.$router.replace({
            name: 'edit-p2p-lending',
            params: {
              id: item.id,
            },
            query: {
              createdAt: item.createdAt,
              documentNo: item.documentNo,
              ...filters,
            },
          });
        }
      } finally {
        this.$root.$loading.hide();
      }
    },
    refactorForm() {
      const form = {
        ...this.form,
        details: this.form.details.map((f) => ({
          ...f,
          totalP2pLending: this.twoDigitComma(f.totalP2pLending),
        })),
      };
      return form;
    },
    async createP2pLending() {
      let valid = true;
      this.$refs.form.forEach((form, index) => {
        const validate = form.validate();
        if (!validate) valid = false;
      });
      const findNullValue = this.form.details.find((detail) => detail.invoiceId === null);
      if (!valid || findNullValue) {
        if (!valid) {
          this.$nextTick(() => {
            const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
            this.$vuetify.goTo(el, { offset: 50 });
          });
        }
        this.$dialog.notify.error('Mohon lengkapi form invoice');
        return;
      }
      try {
        this.$root.$loading.show();
        await this.$_services.p2pLending.createP2pLending(this.refactorForm());
        this.$dialog.notify.success('P2P Lending Berhasil Dibuat');
        this.navigateToEditPageP2pLending();
      } finally {
        this.$root.$loading.hide();
      }
    },
    save() {
      let valid = true;
      this.$refs.form.forEach((form, index) => {
        const validate = form.validate();
        if (!validate) valid = false;
      });
      const findNullValue = this.form.details.find((detail) => detail.invoiceId === null);
      if (!valid || findNullValue) {
        this.$dialog.notify.error('Mohon lengkapi form invoice');
        return;
      }
      this.$dialog.confirm({
        text: 'Anda yakin akan menyimpan?',
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then(async (res) => {
        if (!res) return;
        try {
          this.$root.$loading.show();
          const { id } = this.$route.params;
          await this.$_services.p2pLending.updateP2pLending(id, this.form);
          this.$dialog.notify.success('Berhasil Menyimpan P2P Lending');
          this.$router.replace('/main/p2p-lending');
        } finally {
          this.$root.$loading.hide();
        }
      });
    },
    async submit() {
      let valid = true;
      this.$refs.form.forEach((form, index) => {
        const validate = form.validate();
        if (!validate) valid = false;
      });
      if (!valid) {
        this.$dialog.notify.error('Mohon lengkapi form invoice');
        return;
      }
      this.$dialog.confirm({
        text: 'Anda yakin akan submit?',
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then(async (res) => {
        if (!res) return;
        try {
          this.$root.$loading.show();
          const { id } = this.$route.params;
          await this.$_services.p2pLending.submitP2pLending(id);
          this.$dialog.notify.success('Berhasil Mengirim P2P Lending');
          this.$router.replace('/main/p2p-lending');
        } finally {
          this.$root.$loading.hide();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
  .img-box{
    cursor: pointer;
    margin-bottom: 2px;
    display: block;
    width: 100%;
    border: 1px solid rgb(189, 183, 183);
    height: 120px;
    position: relative;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .box-mdi-close {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 999;
    }
  }
</style>
