<template>
  <div>
    <section>
      <v-divider class="my-4"></v-divider>
      <v-row v-if="datas.lendingStatus">
        <v-col cols="12">
          <h4>{{$_strings.p2pLending.P2P_LENDING_APPROVAL}}</h4>
        </v-col>
        <v-col cols="12">
          <h4>{{$_strings.p2pLending.NOTE}}</h4>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-textarea
            :value="datas.lendingNotes || '-'"
            disabled
            outlined
            dense
            class="grey--text"
          ></v-textarea>
        </v-col>
      </v-row>
    </section>
    <section v-if="datas.paymentStatus && datas.paymentStatus.toLowerCase() === 'sudah dibayar'">
      <v-divider class="my-4"></v-divider>
      <v-row justify="space-between">
        <v-col cols="12" sm="6">
          <h4>{{$_strings.p2pLending.STATUS_PAYMENT}}</h4>
        </v-col>
        <v-col cols="12" sm="auto">
          <span class="font-italic">{{datas.paymentStatus || '-'}}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" class="pb-0">
          <p>{{$_strings.p2pLending.PAYMENT_DATE}}</p>
        </v-col>
        <v-col cols="auto" class="pb-0">
          <p>{{dateFormat(datas.paymentDate)}}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3">
          <p>{{$_strings.p2pLending.PROOF_OF_PAYMENT}}</p>
        </v-col>
        <v-col v-if="!datas.paymentAttachment" class="auto">
          <p class="font-italic">*Belum ada file attachment*</p>
        </v-col>
        <v-col v-else cols="auto" >
          <v-icon>mdi-attachment</v-icon>
          <v-btn
            text
            color="primary"
            @click="openDocument(datas.paymentAttachment)"
          >
            Lampiran
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <h4>{{$_strings.p2pLending.NOTE}}</h4>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-textarea
            :value="datas.paymentNotes || '-'"
            outlined
            dense
            class="grey--text"
            disabled
          ></v-textarea>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import { p2pLendingStatus, dateFormat } from '@/helper/commonHelpers';

export default {
  props: {
    datas: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    p2pLendingStatus,
    dateFormat,
    getColorByStatus(status) {
      switch (status.toLowerCase()) {
        case 'need_approval':
        case 'menunggu persetujuan':
        case 'belum dibayar':
          return {
            background: 'yellow',
            textColor: 'black--text',
          };
        case 'approved':
        case 'disetujui':
          return {
            background: 'green',
            textColor: 'white--text',
          };
        case 'ditolak':
        case 'rejected':
          return {
            background: 'red',
            textColor: 'white--text',
          };
        default:
          return {
            background: 'primary',
            textColor: 'white--text',
          };
      }
    },
    openDocument(url, fileType = '') {
      const imageExtension = ['jpg', 'jpeg', 'png', 'bmp'];
      if (imageExtension.includes(fileType.toLowerCase())) {
        this.dialogViewImage = true;
        this.imageSelected = url;
        return;
      }
      if (fileType.toLowerCase() === 'pdf') {
        this.dialogViewPdf = true;
        this.urlPdfSelected = url;
        return;
      }
      window.open(url);
    },
  },
};
</script>
