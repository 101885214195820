var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":"1200"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"caption"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between align-center pb-0",attrs:{"cols":"10"}},[_c('h3',[_vm._v(_vm._s(_vm.$_strings.p2pLending.SELECT_INVOICE))])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","color":"black","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1),(_vm.isLoading)?_c('v-card-text',{staticClass:"mt-4"},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"heading"}}),_c('v-skeleton-loader',{staticClass:"mx-auto mt-4",attrs:{"type":"image"}})],1):_c('v-card-text',{staticClass:"mt-4"},[_c('FilterInvoice',{staticClass:"mb-4",attrs:{"filters":_vm.filters,"showFilterBy":false},on:{"fetchData":function($event){return _vm.fetchData('isLoadingTable')}}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.isLoadingTable,"loading-text":"Mohon tunggu","calculate-widths":"","items":_vm.items,"server-items-length":_vm.listTotalEntry,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.radioButton",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{attrs:{"column":""},model:{value:(_vm.radioInvoice),callback:function ($$v) {_vm.radioInvoice=$$v},expression:"radioInvoice"}},[_c('v-radio',{attrs:{"color":"primary","value":item.id,"disabled":_vm.invoiceNoSelected.includes(item.invoiceNo)}})],1)]}},{key:"item.periodStart",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.differenceMonth(item.periodStart, item.periodEnd))+" Bulan ")]),_c('p',[_vm._v(" "+_vm._s(((_vm.dateFormat(item.periodStart)) + " - " + (_vm.dateFormat(item.periodEnd))))+" ")])]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.totalAmount && ("Rp. " + (_vm.formatCurrency(item.totalAmount)))))])]}},{key:"item.invoiceDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(("" + (_vm.dateFormat(item.invoiceDate))))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$_strings.invoice.FILTER_STATUS_LIST.find(function (filter) { return filter.value === item.status; }).text))])]}}],null,true)})],1),_c('v-card-actions',{attrs:{"v":""}},[_c('v-row',{staticClass:"ma-0",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"2","sm":"3"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"red","small":"","block":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$_strings.common.CANCEL)+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"3"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","small":"","block":"","disabled":_vm.isLoading || _vm.isLoadingTable || !_vm.radioInvoice},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$_strings.common.CHOOSE)+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }